body,
body * {
  font-family: 'Fira Code', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    sans-serif !important;
}
body {
  margin: 0;
  font-family: 'Fira Code', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  pointer-events: none !important;
  display: none !important;
}

span.dot {
  background: #02A202;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-top: 10px;
}
span.byline {
  margin-top: 8px;
}

.MuiToolbar-root strong {
  font-size: 24px;
}

@media only screen and (max-width: 999px) {
  .MuiToolbar-root strong {
    font-size: 18px;
  }
  span.byline {
    font-size: 12px;
    margin-top: 4px;
    margin-left: -6px;
  }
  span.dot {
    width: 6px;
    height: 6px;
    margin-top: 8px;
    margin-left: -4px;
  }
  .MuiDrawer-paperAnchorRight {
    width: 90% !important;
  }
}

.css-12z0wuy,
.css-120gsus {
  margin-right: 24px !important;
}
.MuiToolbar-root {
  min-height: 83px !important;
}
.MuiToolbar-root * {
  position: relative;
  z-index: 1;
}
.generative {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 83px !important;
  transform: rotate(180deg);
}